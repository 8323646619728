import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the plans
const initialState = {
  plans: [
    {
      id: 'turf',
      title: 'Turf',
      description: 'High-quality turf for your landscaping needs',
      price: '$15.45 per 1000 sqft'
    },
    {
      id: 'standard bare-ground',
      title: 'Standard Bare Ground',
      description: 'Protect your property and equipment with an annual treatment',
      price: '$59.60 per 1000 sqft'
    },
    {
      id: 'noxious-weeds',
      title: 'Noxious Weeds',
      description: 'Effective solutions for noxious weed control',
      price: 'Prices Vary'
    },

  ],
  // Add other state properties if needed
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    // Define reducers here if needed to manipulate the state
  },
  // Add extraReducers if you're handling async operations
});

// Action creators are generated for each case reducer function
export const { } = plansSlice.actions;

// Selector to access the plans in the state
export const selectPlans = (state) => state.plans.plans;

export default plansSlice.reducer;
